<template>
  <div class="outer_container">
    <div
      class="center"
      :class="{ 'back-blur': state.domainBtnDisplay.backBlur }"
    >
      <div
        class="service"
        @click="linkPage('Service')"
      >
        <div class="robot">
          <img
            :src="require('@/assets/img/service.png')"
            alt=""
          >
        </div>
      </div>
      <div class="downloads">
        <swiper
          :slides-per-view="1"
          :centered-slides="true"
          :loop="true"
          :pagination="{
            clickable: true,
          }"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
          }"
          :modules="state.modules"
        >
          <template v-if="state.mainList.length">
            <swiper-slide
              v-for="item in state.mainList"
              :key="item.url"
            >
              <div class="slider-outer">
                <div class="imgs">
                  <img :src="require(`@/assets/img/${item.url}`)">
                </div>
                <div class="text">
                  <div class="btns">
                    <img
                      :src="require('@/assets/img/downloads/button.png')"
                      alt="bet365"
                      class=""
                      @click="linkPage('Download')"
                    >
                  </div>
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <div class="game">
        <div class="title">
          热门游戏
        </div>
        <div class="conts">
          <swiper
            :slides-per-view="4"
            :speed="2500"
            :allow-touch-move="false"
            :autoplay="{
              delay: 0,
              disableOnInteraction: false,
              stopOnLastSlide: false,
            }"
            :loop="true"
            :modules="state.modules"
          >
            <template v-if="state.gameList.length">
              <swiper-slide
                v-for="item in state.gameList"
                :key="item?.url"
              >
                <div class="img-outer">
                  <img :src="require(`@/assets/img/${item?.url}`)">
                </div>
              </swiper-slide>
            </template>
          </swiper>
          <swiper
            :slides-per-view="4"
            :speed="2500"
            :allow-touch-move="false"
            :autoplay="{
              delay: 0,
              disableOnInteraction: false,
              stopOnLastSlide: false,
              reverseDirection: true,
            }"
            :loop="true"
            :modules="state.modules"
          >
            <template v-if="state.gameList.length">
              <swiper-slide
                v-for="item in state.gameList"
                :key="item?.url"
              >
                <div class="img-outer">
                  <img :src="require(`@/assets/img/${item?.url}`)">
                </div>
              </swiper-slide>
            </template>
          </swiper>
        </div>
      </div>
      <div class="shortcut">
        <div class="title flex">
          <div class="text">
            快捷登录站点
          </div>
          <div
            class="refresh"
            @click="setUrlList"
          >
            <img
              class="refresh-icon"
              :src="require('@/assets/img/downloads/refresh.png')"
              alt="bet365"
            >
            <div
              class="change"
            >
              换一换
            </div>
          </div>
        </div>
        <template v-if="state.urlList.length">
          <div
            class="site-card"
            v-for="(item, index) in state.urlList"
            :key="item?.url || `site-card${index}`"
            @click.stop="redirect(item?.url)"
          >
            <div class="route-icon">
              <img
                :src="$requireSafe(`route/route-${index + 1}.png`)"
                alt="bet365"
              >
            </div>
            <div class="route-desc">
              <div class="route-title">
                <div class="route-slogan">
                  bet365极速站点
                </div>
                <img
                  class="route-copy"
                  :src="$requireSafe(`downloads/copy.png`)"
                  alt="bet365"
                  @click.stop="copyByText(item.url)"
                >
              </div>
              <div class="route-url">
                {{ transferUrl(item?.url) }}
              </div>
              <div class="route-ping">
                {{ item?.ping }}ms/s
              </div>
            </div>
            <div class="route-link">
              点击进入
            </div>
          </div>
          <template />
        </template>
      </div>
      <div class="partner">
        <div class="title">
          合作伙伴
        </div>
        <swiper
          :slides-per-view="'auto'"
          :loop="true"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
          }"
          :modules="state.modules"
        >
          <template v-if="state.partnerList.length">
            <swiper-slide
              v-for="item in state.partnerList"
              :key="item?.url"
            >
              <div class="img-outer">
                <img :src="require(`@/assets/img/${item?.url}`)">
              </div>
              <div class="txts">
                {{ item.text }}
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <div class="star">
        <div class="title">
          球星风采
        </div>
        <swiper
          :slides-per-view="'auto'"
          :space-between="7"
          :breakpoints="{
            750:{ spaceBetween: 28 },
            560:{ spaceBetween: 14 },
          }"
          centered-slides
          :loop="true"
          :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
          }"
          :modules="state.modules"
        >
          <template v-if="state.starList.length">
            <swiper-slide
              v-for="item in state.starList"
              :key="item?.url"
            >
              <div class="img-outer">
                <img :src="require(`@/assets/img/${item?.url}`)">
              </div>
              <div class="txts">
                <div class="txts-title">
                  {{ item.title }}
                </div>
                {{ item.text }}
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <div class="footer">
        <div class="line">
          <ul>
            <li
              v-for="item in state.footerList"
              :key="item?.text"
            >
              <div class="ico">
                <img
                  :src="require(`@/assets/img/${item?.url}`)"
                  alt=""
                >
              </div>
              <div class="txt">
                <p>{{ item?.text }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="line">
          <div class="image">
            <img
              :src="require('@/assets/img/logo/logo.png')"
              alt="LOGO"
            >
            <img
              :src="require('@/assets/img/footer/fifa-worldcup.png')"
              alt="worldcup"
            >
          </div>
          <div class="text">
            2022卡塔尔世界杯官方合作伙伴
          </div>
          <a href="https://www.365777.com">https://www.365777.com</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
  ref, reactive, onMounted, onBeforeMount,
} from 'vue';
import { Autoplay, Pagination } from 'swiper';
import Swal from 'sweetalert2';
import 'swiper/css/pagination';

// Import Swiper styles
import 'swiper/css';

const maxCount = 20;

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    // data
    const brandName = ref('欢迎光临');
    const activeRankTab = ref(0);
    const activeScheduleTab = ref(0);
    const downloadLink = ref('');
    const serviceLink = ref('');

    // reavtive
    const state = reactive({
      mainList: [
        { url: 'downloads/home_01.png' },
        { url: 'downloads/home_02.png' },
        { url: 'downloads/home_03.png' },
        { url: 'downloads/home_04.png' },
        { url: 'downloads/home_05.png' },
      ],
      gameList: [
        { url: 'game/game1.png' },
        { url: 'game/game2.png' },
        { url: 'game/game3.png' },
        { url: 'game/game4.png' },
        { url: 'game/game5.png' },
        { url: 'game/game6.png' },
        { url: 'game/game7.png' },
        { url: 'game/game8.png' },
        { url: 'game/game9.png' },
        { url: 'game/game10.png' },
        { url: 'game/game11.png' },
        { url: 'game/game12.png' },
        { url: 'game/game13.png' },
        { url: 'game/game14.png' },
        { url: 'game/game15.png' },
        { url: 'game/game16.png' },
        { url: 'game/game17.png' },
        { url: 'game/game18.png' },
        { url: 'game/game19.png' },
      ],
      partnerList: [
        {
          url: 'partner/partner_01.png',
          text: 'bet365品牌大使：卢卡·莫德里奇',
        },
        {
          url: 'partner/partner_02.png',
          text: '2022卡塔尔世界杯官方合作伙伴',
        },
        {
          url: 'partner/partner_03.png',
          text: 'bet365品牌大使：基利安·姆巴佩',
        },
        {
          url: 'partner/partner_04.png',
          text: 'bet365品牌大使：利昂内尔·梅西',
        },
      ],
      starList: [
        {
          url: 'star/star_01.png',
          title: '凯·哈佛茨 2022世界杯十佳进球',
          text: '品牌大使: 凯·哈佛茨',
        },
        {
          url: 'star/star_02.png',
          title: '基利安·姆巴佩 2022世界杯十佳进球',
          text: '品牌大使: 基利安·姆巴佩',
        },
        {
          url: 'star/star_03.png',
          title: '巴黎圣日耳曼法甲夺冠瞬间',
          text: '品牌战略合作伙伴',
        },
        {
          url: 'star/star_04.png',
          title: '埃尔林·哈兰德 2022世界杯十佳进球',
          text: '品牌大使: 埃尔林·哈兰德',
        },
        {
          url: 'star/star_05.png',
          title: '哈里·凯恩 2022世界杯十佳进球',
          text: '品牌大使: 哈里·凯恩',
        },
        {
          url: 'star/star_06.png',
          title: '克罗埃西亚最强中场大师-魔笛',
          text: '品牌大使: 卢卡·莫德里奇',
        },
      ],
      footerList: [
        { url: 'footer/mga.png', text: '马耳他牌照(MGA）认证' },
        { url: 'footer/pagcor.png', text: '菲律宾 PAGCOR监督执照' },
        { url: 'footer/bvi.png', text: '英属维尔京群岛（BVI）认证' },
        { url: 'footer/gc.png', text: '英国GC监督委员会' },
      ],
      modules: [Autoplay, Pagination],
      randoms: [],
      urlList: [],
      indexList: [],
      domainBtnDisplay: {
        backBlur: false,
        showDomainBtn: false,
      },
    });

    // method
    const setActive = (index, type) => {
      switch (type) {
      case 'Rank':
        activeRankTab.value = index;
        break;
      case 'Schedule':
        activeScheduleTab.value = index;
        break;
      default:
        break;
      }
    };

    const linkPage = (type) => {
      switch (type) {
      case 'Service':
        if (window._jsvar?.siteOpenBlank) {
          window.open(serviceLink.value, '_blank');
        } else {
          window.location = serviceLink.value;
        }
        break;
      case 'Download':
        if (window._jsvar?.siteOpenBlank) {
          window.open(downloadLink.value, '_blank');
        } else {
          window.location = downloadLink.value;
        }
        break;
      default:
        break;
      }
    };

    const getSiteInfo = async () => {
      serviceLink.value = window._jsvar.siteServiceUrl;
      downloadLink.value = window._jsvar.siteAppUrl;
    };

    const toggleDomainBtnVariable = (variable) => {
      state.domainBtnDisplay[variable] = !state.domainBtnDisplay[variable];
    };

    const redirectShow = () => {
      const { body } = document;
      body.style.overflow = 'hidden';
      toggleDomainBtnVariable('backBlur');
      toggleDomainBtnVariable('showDomainBtn');
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    };

    const closeRedirect = () => {
      const { body } = document;
      body.style.overflow = 'auto';
      toggleDomainBtnVariable('showDomainBtn');
      toggleDomainBtnVariable('backBlur');
    };

    const getRandomNum = () => {
      state.randoms = [];
      while (state.randoms.length < 3) {
        const randomNum = Math.floor(Math.random() * window._jsvar?.siteDomainList?.length);

        if (!state.randoms.includes(randomNum)) {
          state.randoms.push(randomNum);
        }
      }
    };

    const setUrlList = () => {
      state.urlList = [];
      const allList = window._jsvar.siteDomainList.map((item) => ({
        url: item,
        ping: '10',
        disabled: false,
      }));

      getRandomNum();

      state.randoms.forEach((item) => {
        state.urlList.push(allList[item]);
      });
    };

    const transferUrl = (url) => {
      if (url.includes('https://')) {
        url = url.replace('https://', '');
      }
      //  else if (url.includes('//www.')) {
      //   url = url.replace('//www.', 'http://');
      // }
      return url;
    };

    const getArrayAvg = (array) => {
      const len = array.length;
      let sum = 0;
      for (let i = 0; i < len; i++) {
        sum += array[i];
      }
      return sum / len;
    };

    const urlSort = () => {
      state.urlList.sort((a, b) => {
        if (a.disabled === b.disabled) {
          return a.ping - b.ping;
        }

        return a.disabled - b.disabled;
      });
    };

    const getLoadTime = (url) => new Promise((resolve) => {
      const img = new Image();
      const timeStart = new Date();
      img.src = url;
      img.onerror = function run() {
        const timeEnd = new Date();
        resolve({
          time: timeEnd.getTime() - timeStart.getTime(),
          disabled: true,
        });
      };
      img.onload = function run() {
        const timeEnd = new Date();
        resolve({
          time: timeEnd.getTime() - timeStart.getTime(),
          disabled: false,
        });
      };
    });

    const getPingArr = () => {
      const storagePingArr = [];
      state.urlList?.forEach(() => {
        storagePingArr.push([]);
      });
      state.urlList?.forEach((item, index) => {
        const loadTimer = setInterval(async () => {
          const { time, disabled } = await getLoadTime(
            `${item?.url}/favicon.ico`,
          );
          if (!time) return;
          storagePingArr[index].push(time);
          if (item) {
            item.ping = time;
            item.disabled = disabled;
          }
          state.indexList.push(index);
          if (storagePingArr[index].length === maxCount) {
            for (let i = 0; i < 2; i++) {
              const maxPing = Math.max.apply(null, storagePingArr[index]);
              storagePingArr[index].splice(
                storagePingArr[index].indexOf(maxPing),
                1,
              );
            }
            const finalPing = getArrayAvg(storagePingArr[index]);
            item.ping = Math.round(finalPing);

            clearInterval(loadTimer);
            if (
              index === state.indexList[state.indexList.length - 1]
              && state.indexList.length === maxCount * state.urlList.length
            ) {
              urlSort();
            }
          }
        }, 1000);
      });
    };

    const $requireSafe = (path) => {
      try {
        /* eslint-disable-next-line */
        return require(`@/assets/img/${path}`);
      } catch (err) {
        return undefined;
      }
    };

    // const redirectHttps = (url) => {
    //   if (window._jsvar?.siteAgentCode) {
    //     if (window._jsvar?.siteOpenBlank) {
    //       window.open(`${url}?a=x&c=${window._jsvar.siteAgentCode}`, '_blank');
    //     } else {
    //       window.location = `${url}?a=x&c=${window._jsvar.siteAgentCode}`;
    //     }
    //   } else if (window._jsvar?.siteOpenBlank) {
    //     window.open(url, '_blank');
    //   } else {
    //     window.location = url;
    //   }
    // const xhr = new XMLHttpRequest();
    // let requestUrl = '';
    // if (url?.includes('//www.')) {
    //   requestUrl = url?.replace('//www.', 'https://');
    // } else {
    //   requestUrl = url;
    // }
    // const siteHost = `${requestUrl}/site/info/get`;
    // xhr.open('GET', siteHost, true);
    // xhr.onload = () => {
    //   if (xhr.readyState === 4) {
    //     if (xhr.status === 200) {
    //       if (JSON.parse(xhr.response)?.siteId === window._jsvar?.siteId) {
    //         if (window._jsvar?.siteAgentCode) {
    //           if (window._jsvar?.siteOpenBlank) {
    //             window.open(`${url}?a=x&c=${window._jsvar.siteAgentCode}`, '_blank');
    //           } else {
    //             window.location = `${url}?a=x&c=${window._jsvar.siteAgentCode}`;
    //           }
    //         } else if (window._jsvar?.siteOpenBlank) {
    //           window.open(url, '_blank');
    //         } else {
    //           window.location = url;
    //         }
    //       } else {
    //         Swal.fire({
    //           icon: 'error',
    //           title: '侦测到线路异常，请选择其它条线路',
    //         });
    //       }
    //     } else {
    //       Swal.fire({
    //         icon: 'error',
    //         title: '侦测到线路异常，请选择其它条线路',
    //       });
    //       console.error(xhr.statusText);
    //     }
    //   }
    // };
    // xhr.onerror = () => {
    //   console.error(xhr?.statusText);
    // };
    // xhr.send(null);
    // };

    const redirect = (url) => {
      if (window._jsvar?.siteAgentCode) {
        if (window._jsvar?.siteOpenBlank) {
          window.open(`${url}?a=x&c=${window._jsvar.siteAgentCode}`, '_blank');
        } else {
          window.location = `${url}?a=x&c=${window._jsvar.siteAgentCode}`;
        }
      } else if (window._jsvar?.siteOpenBlank) {
        window.open(url, '_blank');
      } else {
        window.location = url;
      }
      // const xhr = new XMLHttpRequest();
      // const siteHost = `${url}/site/info/get`;
      // xhr.open('GET', siteHost, true);
      // xhr.onload = () => {
      //   if (xhr.readyState === 4) {
      //     if (xhr.status === 200) {
      //       if (JSON.parse(xhr.response)?.siteId === window._jsvar?.siteId) {
      //         if (window._jsvar?.siteAgentCode) {
      //           if (window._jsvar?.siteOpenBlank) {
      //             window.open(`${url}?a=x&c=${window._jsvar.siteAgentCode}`, '_blank');
      //           } else {
      //             window.location = `${url}?a=x&c=${window._jsvar.siteAgentCode}`;
      //           }
      //         } else if (window._jsvar?.siteOpenBlank) {
      //           window.open(url, '_blank');
      //         } else {
      //           window.location = url;
      //         }
      //       } else {
      //         Swal.fire({
      //           icon: 'error',
      //           text: '侦测到线路异常，请选择其它条线路',
      //           width: 300,
      //           timer: 800,
      //           heightAuto: true,
      //           timerProgressBar: true,
      //           showConfirmButton: false,
      //         });
      //       }
      //     } else {
      //       Swal.fire({
      //         icon: 'error',
      //         text: '侦测到线路异常，请选择其它条线路',
      //         width: 300,
      //         timer: 800,
      //         heightAuto: true,
      //         timerProgressBar: true,
      //         showConfirmButton: false,
      //       });
      //       console.error(xhr.statusText);
      //     }
      //   }
      // };
      // xhr.onerror = () => {
      //   console.error(xhr?.statusText);
      //   redirectHttps(url);
      // };
      // xhr.send(null);
    };

    const copyByText = (text, parseHtml = false) => {
      const oInput = document.createElement('input');
      if (text) {
        let parseText;
        // if (text?.includes('//www.')) {
        //   parseText = text?.replace('//www.', '');
        // } else {
        //   parseText = text;
        // }
        parseText = text;
        if (parseHtml) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(text, 'text/html');
          parseText = doc.documentElement.textContent;
        }
        oInput.value = parseText;
        document.body.appendChild(oInput);
        oInput.select();
        document.execCommand('Copy');
        oInput.className = 'oInput';
        oInput.parentNode.removeChild(oInput);
        Swal.fire({
          text: '复制成功',
          width: 150,
          timer: 700,
          heightAuto: true,
          timerProgressBar: true,
          showConfirmButton: false,
        });
      }
    };

    onBeforeMount(() => {
      setUrlList();
      getPingArr();
    });

    onMounted(() => {
      getSiteInfo();
    });

    return {
      brandName,
      activeRankTab,
      activeScheduleTab,
      state,
      downloadLink,
      serviceLink,
      setActive,
      linkPage,
      redirectShow,
      closeRedirect,
      transferUrl,
      $requireSafe,
      setUrlList,
      redirect,
      copyByText,
    };
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  padding: 0 15px;
  color: #000;
  font-weight: 600;
  font-size: 22px;
  line-height: 35px;
}

.downloads {
  position: relative;
  margin-top: -192px;

  @media screen and (max-width: 560px) {
    margin-top: -100px;
  }

  .download-teach {
    position: absolute;
    top: 28px;
    right: 20px;
    z-index: 10;
    color: #fff;
    font-size: 13px;
    cursor: pointer;
  }

  .logo {
    position: absolute;
    top: 3%;
    left: 50%;
    width: 100px;
    transform: translate(-50%, 50%);
  }

  .slider-outer {
    position: relative;

    .btns {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100px;
      padding-bottom: 20px;
      animation: btnBounce 1s infinite;

      img {
        height: 100%;
        object-fit: contain;
        cursor: pointer;
      }

      @media screen and (max-width: 560px) {
        padding-bottom: 10px;

        img {
          width: 260px;
        }
      }
    }

    .text {
      position: absolute;
      bottom: 100px;
      left: 0;
      width: 100%;

      @media screen and (max-width: 560px) {
        bottom: 30px;
      }

      .txts {
        display: flex;
        justify-content: center;
        width: 100%;
        color: #fff;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        p {
          margin-left: 5px;
          color: #ffd800;
        }
      }
    }
  }

  ::v-deep(.swiper-pagination) {
    bottom: 13px;

    .swiper-pagination-bullet {
      width: 18px;
      height: 3px;
      margin: 0 4.5px;
      border-radius: 3px;
      background-color: #fff;
      opacity: 0.6;

      &-active {
        width: 40px;
        background-color: #fff23b;
        opacity: 1;
      }
    }
  }
}

.back-blur {
  filter: blur(30px);
}

.hidden {
  overflow: hidden;
}

.outer_container {
  position: relative;

  .blur {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;

    .mask {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .jump_to {
      position: absolute;
      top: 6%;
      left: 6%;
      display: flex;
      flex-direction: column;
      width: 88%;
      height: 14%;
      border-radius: 5px;
      background-color: #fff;

      .jump_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background-color: #0083f0;

        .title {
          color: #fff;
          font-size: 1rem;
          letter-spacing: 2px;
        }

        .close {
          margin-right: 10px;
          cursor: pointer;

          img {
            width: 75%;
            height: 75%;
          }
        }
      }

      .jump_redirect {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 15px;

        .site_choice {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 13%;
          margin: 10px 0;

          .pin-site {
            display: flex;
            align-items: center;
            box-sizing: border-box;
            width: 80%;
            height: 90%;
            margin-right: 10px;
            border: 2px solid #223f6f;
            border-radius: 10px;
            font-size: 1rem;

            .pin {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 30%;
              height: 102%;
              border-top-left-radius: 7px;
              border-bottom-left-radius: 7px;
              color: #fff;
              background-color: #223f6f;
            }

            .site {
              display: flex;
              flex: 1;
              align-items: center;
              justify-content: center;
            }
          }

          .enter {
            width: 20%;
            height: 100%;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;

              /* stylelint-disable-next-line max-nesting-depth */
              img {
                width: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 560px) {
      top: -1%;
      left: 0%;
    }
  }
}

.game {
  padding-top: 12px;

  ::v-deep(.swiper-wrapper) {
    margin: 14px 0;
    transition-timing-function: linear;
  }

  .img-outer {
    width: 137px;
    margin-right: 28px;

    @media screen and (max-width: 670px) {
      width: 80px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.partner {
  padding-top: 8px;
  padding-bottom: 14px;

  ::v-deep(.swiper-wrapper) {
    margin: 14px 0;
    padding-left: 15px;

    .swiper-slide {
      width: unset;
    }
  }

  .logo {
    position: absolute;
    top: 25px;
    right: 20px;
    width: 50px;
  }

  .img-outer {
    width: 274px;
    margin-right: 14px;
    padding-bottom: 8px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    position: absolute;
    top: 63.5px;
    right: 20px;
    color: rgba(255, 255, 255, 0.89);
    font-size: 10px;
  }

  .txts {
    width: 274px;
    color: #333;
    font-size: 15px;
    line-height: 20px;
  }
}

.star {
  margin-bottom: 40px;
  padding: 14px 0;
  border-top: 7px solid #eee;
  border-bottom: 7px solid #eee;

  ::v-deep(.swiper-wrapper) {
    margin-top: 10px;
    padding-bottom: 14px;

    .swiper-slide {
      width: 548px;
      border-radius: 15px;
      box-shadow: 0 6.5px 9.5px rgba(185, 187, 200, 30%);

      @media screen and (max-width: 670px) {
        width: 316px;
      }
    }
  }

  .img-outer {
    max-height: 488px;
    border-radius: 10px;
    overflow: hidden;

    .item {
      border-radius: 15px;
      box-shadow: 0 6.5px 9.5px rgba(185, 187, 200, 30%);

      .img {
        height: 392px;
        border-radius: 6.5px 6.5px 0 0;
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
      }
    }

    >img {
      width: 100%;
    }

    @media screen and (max-width: 670px) {
      max-height: 281px;

      >img {
        width: 316px;
      }
    }
  }

  .txts {
    margin-top: 10px;
    padding: 20px 30px;

    @media screen and (max-width: 560px) {
      padding: 5px 15px 20px;
    }

    .txts-title {
      font-weight: 600;
      font-size: 18px;
    }
  }
}

.ranks {
  padding: 20px 20px 0;
  border-top: 7px solid #eee;

  .title img {
    height: 24px;
    margin: 0 auto;
  }

  .subtitle {
    display: flex;
    align-items: center;

    p {
      margin: 14px 0 8px;
      font-weight: 600;
      font-size: 15px;
    }

    img {
      width: 8px;
      height: 13px;
      margin-top: 6px;
      margin-left: 5px;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    height: 42px;
    padding: 18px 0;
    border-radius: 4px;
    background-color: #ebedf1;

    ul {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0 8px;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 3px 8px;
        font-size: 14px;
        white-space: nowrap;
        cursor: pointer;

        &.active {
          border-radius: 3px;
          background-color: #fff;
        }
      }
    }
  }

  table {
    width: 100%;
    margin-top: 12px;

    tr {
      th {
        border-bottom: 1px solid #eaeaea;
        color: #969696;
        font-weight: 600;
        font-size: 14px;
        line-height: 33px;
      }

      th:first-child {
        width: 55%;
        text-align: left;
      }

      td {
        line-height: 30px;
        text-align: center;

        &:first-child {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
      }

      .country-flag {
        width: 15px;
        margin: 0 8px;
      }
    }

    tbody tr {
      .rank-flag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 15px;
        height: 17px;
        color: #fff;
        font-size: 10px;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:first-child .rank-flag {
        background-image: url('./assets/img/worldcup/first.png');
      }

      &:nth-child(2) .rank-flag {
        background-image: url('./assets/img/worldcup/second.png');
      }

      &:nth-child(3) .rank-flag {
        background-image: url('./assets/img/worldcup/third.png');
      }

      &:nth-child(4) .rank-flag {
        background-image: url('./assets/img/worldcup/fourth.png');
      }
    }
  }

  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 14px 0;
    border-bottom: 1px solid #f6f6f6;
    font-size: 12px;

    a {
      display: flex;
      align-items: center;
      color: #969696;

      img {
        width: 10px;
        margin-left: 3px;
      }
    }
  }
}

.schedule {
  padding: 0 20px;

  .subtitle {
    display: flex;
    align-items: center;

    p {
      margin: 14px 0 8px;
      font-weight: 600;
      font-size: 15px;
    }

    img {
      width: 8px;
      height: 13px;
      margin-top: 6px;
      margin-left: 5px;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    height: 42px;
    padding: 18px 0;
    border-radius: 4px;
    background-color: #ebedf1;

    .swiper {
      padding: 0 8px;

      .swiper-slide {
        width: 90px;
        padding: 3px 8px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;

        &.active {
          border-radius: 3px;
          background-color: #fff;
        }
      }
    }
  }

  table {
    width: 100%;

    .subtitle {
      margin: 0;
      font-size: 8px;
      line-height: 16px;
    }

    .gray {
      color: #8c8c8c;
    }

    .country {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 30px;

      img {
        height: 16px;
        margin-right: 8px;
      }
    }

    tr td {
      border-bottom: 1px solid #f5f5f5;
      text-align: center;

      &:first-child {
        width: 20%;
        text-align: start;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 25%;

        p {
          margin: 6px;
        }
      }

      &:nth-child(4) {
        width: 25%;
      }
    }
  }

  .more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 14px 0;
    border-bottom: 1px solid #f6f6f6;
    font-size: 12px;

    a {
      display: flex;
      align-items: center;
      color: #969696;

      img {
        width: 10px;
        margin-left: 3px;
      }
    }
  }
}

.footer {
  padding: 10px 0;
  background-color: #edeef1;

  ul {
    display: flex;
    padding: 0;
    list-style: none;

    li {
      width: 25%;

      .ico {
        display: flex;
        justify-content: center;

        img {
          width: 60px;
          object-fit: contain;
        }
      }

      .txt {
        display: flex;
        justify-content: center;

        p {
          width: 80%;
          margin: 6px;
          color: #6f6f6f;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
        }
      }
    }
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 20px;
      padding: 0 16px;

      &:first-child {
        border-right: 1px solid #e7e7e7;
      }
    }
  }

  .line {
    text-align: center;

    .text {
      margin: 8px 0 0;
      color: #6f6f6f;
      font-size: 14px;
    }

    a {
      margin: 0;
      color: #6f6f6f;
      font-size: 12px;
    }
  }
}

.service {
  position: sticky;
  top: 50%;
  left: 100%;
  z-index: 50;
  width: 124px;
  cursor: pointer;

  @media screen and (max-width: 560px) {
    width: 64px;
    margin-right: 0;
  }
}

.teach-modal {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  max-width: 450px;
  height: 100%;
  background-image: url('./assets/img/teach/teach_mobile_bg.png');
  background-repeat: no-repeat;

  .modal-body {
    position: relative;
    width: 100%;
    height: 100vh;
    padding-bottom: 60px;
    overflow: auto;
  }

  header {
    position: fixed;
    z-index: 3;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 450px;
    padding: 14px;
    color: #fff;
    font-size: 15px;
    background: #1d2841;

    img {
      position: absolute;
      left: 16px;
      width: 16px;
      transform: rotate(180deg);
      cursor: pointer;
    }
  }

  .container {
    padding-top: 50px;
    overflow: auto;
    background-image: url('./assets/img/teach/teach-bg.svg');
    background-repeat: no-repeat;

    p {
      padding: 15px 0;
      color: #fff;
      font-weight: 600;
      text-align: center;
    }
  }

  .tab-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    input {
      display: none;
    }

    label {
      position: relative;
      margin: 0 20px;
      color: #fff;
      transition: all 0.5s;
      cursor: pointer;
    }

    input:checked + label {
      color: #fee819;

      &::after {
        position: absolute;
        top: 100%;
        bottom: -3px;
        left: 50%;
        width: 10px;
        border-bottom: 2px solid #fee819;
        border-radius: 3px;
        content: '';
        transform: translate(-50%, -50%);
      }
    }

    input:checked + label + .tab_content {
      display: initial;
    }

    .tab_content {
      display: none;
      order: 1;
      width: 100%;
      padding: 15px 20px;

      .text {
        color: #fff;
        opacity: 0.5;
      }
    }
  }
}

@keyframes btnBounce {
  from {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  to {
    transform: scale(1);
  }
}

.shortcut {
  padding: 28px 0;

  .title {
    display: flex;
    justify-content: space-between;

    .refresh {
      display: flex;
      align-items: center;
      cursor: pointer;

      .refresh-icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;

        @media screen and (max-width: 560px) {
          width: 18px;
          height: 18px;
          font-size: 18px;
        }
      }

      .change {
        color: #6f6f6f;
        font-size: 18px;
      }
    }
  }
}

.site-card {
  display: flex;
  align-items: center;
  padding: 30px 5px;
  border-bottom: 1px solid #eee;
  cursor: pointer;

  .route-icon {
    width: 100px;
    height: 100px;
    margin-right: 30px;

    @media screen and (max-width: 560px) {
      width: 60px;
      height: 60px;
      margin-right: 15px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .route-desc {
    .route-title {
      display: flex;
      align-items: center;

      .route-slogan {
        margin-right: 5px;
        color: #543e31;
        font-size: 16px;
      }

      .route-copy {
        width: 24px;
        height: 24px;
      }
    }

    .route-url {
      color: #000;
      font-weight: 700;
      font-size: 22px;

      @media screen and (max-width: 560px) {
        font-size: 18px;
      }
    }

    .route-ping {
      color: #6f6f6f;
      font-size: 16px;
    }
  }

  .route-link {
    margin: 0 0 0 auto;
    padding: 10px;
    border-radius: 20px;
    color: #fff;
    background: #36ad8a;

    @media screen and (max-width: 560px) {
      padding: 5px 10px;
      font-size: 16px;
    }
  }
}
</style>
